import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import NavBar from './components/NavBar';

import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import LogoutPage from './pages/LogoutPage';
import QuarterlyTargets from './pages/QuarterlyTargets';
import QuarterlyTargetsTravelDate from './pages/QuarterlyTargetsTravelDate';

import { AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react';
import NoDataPage from './pages/NoDataPage';

function App() {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <Router>
          <Routes>
            <Route path="*" element={<><DashboardPage /><NavBar /></>} />
            <Route path="/" element={<><DashboardPage /><NavBar /></>} />
            <Route path="/quarterly" element={<><QuarterlyTargets /><NavBar /></>} />
            <Route path="/quarterly-travel-date" element={<><QuarterlyTargetsTravelDate /><NavBar /></>} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/no-data" element={<NoDataPage />} />
          </Routes>
        </Router>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Router>
          <Routes>
            <Route path="*" element={<LoginPage />} />
          </Routes>
        </Router>
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
