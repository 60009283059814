import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import MSButton from '../images/ms-symbollockup_signin_dark_short.svg'

export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch((e) => {
            console.log(e);
        });

    };

    return (
        <>
            <button className="mt-5" as="button" onClick={() => handleLogin()}>
                <img src={MSButton} />
            </button>
        </>

    );
};