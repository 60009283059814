import React from "react";
import { useMsal } from "@azure/msal-react";

/**
 * Renders a sign out button 
 */
export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
  
  };

  return (
      <button className="inline-flex justify-center rounded-lg text-sm font-semibold py-3 px-4 bg-white text-slate-900 ring-1 ring-slate-900/10 hover:bg-white/75 hover:ring-slate-900/15 " as="button" onClick={() => handleLogout("redirect")}>
        Sign out
      </button>
  );
};