import React from 'react';
import EpicLogo from '../images/epic-dash-logo-white.svg'
import { SignInButton } from '../components/SignInButton';

function LoginPage() {
  return (
    <div className='LoginWrapper'>
      <div>
        <div><img className='LoginLogo' src={EpicLogo} alt="Epic Dash" /></div>
        <SignInButton />
        <div style={{color: "#fff", fontSize: "13px", width: "100%", position: "fixed", bottom: "20px", textAlign: "center", left: 0}}>v2.0.0</div>
      </div>
    </div>
  )
}

export default LoginPage