import React from 'react'
import { SignOutButton } from '../components/SignOutButton';
import { useLocation } from "react-router-dom";



function NoDataPage(props) {

  const location = useLocation();

  return (
    
    <div className='LoginWrapper'>
      <div>
        <div className='mb-4'>
          {location.state.code && <p className='text-white'>{location.state.code}</p>}
          <h2 className='text-white text-3xl'>No Data Found</h2>
        </div>
        <SignOutButton />
      </div>
    </div>
    
  )
}

export default NoDataPage