import React from 'react'
import { useEffect } from 'react'
import { useMsal } from "@azure/msal-react";

function LogoutPage() {
    const { instance } = useMsal();

    useEffect(() => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }, []);
    
    return (
        <>
        </>
    )
}

export default LogoutPage